
@import '~@/assets/sass/design.sass'

.list
  padding: 0
  list-style: none
.head
  padding: 0 $base-spacing
.item
  padding: $base-spacing
  background: $blue-gradient
  border-radius: 8px
  &Inner
    display: grid
    grid-template-columns: 60px 120px auto 60px 60px 60px
    grid-gap: $base-spacing
.item~.item
  margin-top: $base-spacing
.photo
  position: relative
