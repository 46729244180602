
// COLORS

// Basic Colors
$c-white: #fff
$c-alto-grey: #d9d9d9
$c-boulder-grey: #787878
$limed-spruce: #2F3E45
$c-ebony-clay-gray: #212733
$c-steel-gray: #1e1e2f
$c-black: #000



$c-red: #E00600
$c-sunglo-red: #E27372
$c-burning-orange: #FF793D
$c-candlelight-yellow: #FEDB10
$c-shamrock-green: #2CCC70
$c-ocean-green: #39AF78
$c-mountain-meadow: #1BBC9C
$c-caribbean-green: #00CDAC
$c-bondi-blue: #02AAB0
$c-picton-blue: #4FC1F4
$c-curious-blue: #3798DD
$c-pickled-bluewood-blue: #263148
$c-shark-blue: #1e1e24
$c-ebony-clay: #27293d
$c-san-juan-blue: #344675
$c-kashmir-blue: #516395
$c-affair-purple: #614385
$c-wisteria-purple: #9A59B5
$c-orchid-pink: #e14eca
$c-blush-pink: #FD75F7

// Accents
$c-acc-green: $c-ocean-green
$c-acc-green-dark: darken($c-acc-green, 20%)
$c-acc-green-hover: darken($c-acc-green, 5%)

$c-acc-red: $c-sunglo-red
$c-acc-red-dark: darken($c-acc-red, 20%)
$c-acc-red-hover: darken($c-acc-red, 5%)

$c-acc-blue: $c-picton-blue
$c-acc-blue-dark: darken($c-acc-blue, 20%)
$c-acc-blue-hover: darken($c-acc-blue, 5%)

$c-acc-pink: $c-orchid-pink
$c-acc-pink-dark: darken($c-acc-pink, 20%)
$c-acc-pink-hover: darken($c-acc-pink, 5%)

// Gradients
$green-gradient: linear-gradient(135deg, $c-acc-green 0%, $c-acc-blue-dark 100%)
$green-grey-gradient: linear-gradient(135deg, $c-acc-green 0%, lighten($c-acc-green, 20%) 50%, $limed-spruce 100%)
$green-blue-purple-gradient: linear-gradient(310deg, $c-affair-purple, $c-kashmir-blue, $c-bondi-blue, $c-caribbean-green)
$blue-gradient: linear-gradient(90deg, $c-san-juan-blue, $c-pickled-bluewood-blue, $c-san-juan-blue)
$dark-gradient: linear-gradient($c-steel-gray, $c-shark-blue)
$pink-blue-gradient: linear-gradient(135deg, $c-blush-pink, $c-blush-pink, $c-bondi-blue, $c-caribbean-green)
$black-transparent-gradient: linear-gradient(0deg, rgba(0,0,0,0.9374124649859944) 0%, rgba(0,0,0,0.46122198879551823) 75%, rgba(0,0,0,0) 100%)


// Property Oriented Colors
$text-faded: $c-boulder-grey
