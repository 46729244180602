// SIZES
$s-xxs: 4px
$s-xs: 8px
$s-s: 12px
$s-m: 20px
$s-l: 32px
$s-xl: 52px
$s-xxl: 84px
$s-xxxl: 136px

// BREAKPOINTS
$sm: 768px
$md: 992px
$lg: 1200px
$xl: 1800px

$desktop: $lg
$widescreen: $xl


// SPACING
$base-spacing: $s-m

// ELEMENTS
$header-height: $s-xxl
$pagination-height: $s-l
$table-header: $s-xl